import React, { useEffect, useState } from "react"
import { Collapse } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import axios from "../../utils/axios"
import Observeble from "../../utils/Observeble"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ExpandMore, ExpandLess } from "@material-ui/icons"
export const observeble = new Observeble()

const targetKey = "@"

const ChildBlock = ({ element, isVisible }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [actionLogin, setActionLogin] = useState(false)
  const [actionIsLogin, setActionIsLogin] = useState(false)
  const history = useHistory()

  const userData = useSelector((state) => state.user?.userData)
  //62f493ea370a34ced8503138

  const getData = () => {
    axios.get("global-entity-types").then((res) => {
      setData(res)
    })
  }

  function downHandler({ key }) {
    if (key === targetKey) {
      setActionLogin(true)
      setTimeout(() => {
        setActionLogin(false)
      }, 5000)
    }
  }

  const upHandler = ({ key }) => {}

  const handeChangeNavbar = (e) => {
    if (e.target.value === "001") setActionIsLogin(true)
  }

  const handleLink = (path) => {
    history.push(path)
    setActionLogin(false)
  }

  useEffect(() => {
    getData()

    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)

    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev)
  }

  const handleDropdownToggleFalse = () => {
    setDropdownOpen(false)
  }

  return (
    <Collapse
      in={isVisible}
      timeout={{
        enter: 300,
        exit: 200,
      }}
    >
      <div className="child-block">
        {element.title === "applications"
          ? data?.map((d, index) =>
              d.id === "614ee88472138f62f9a8f495" && userData?.role?.id === "6136faeec55c3d063b28a118" ? (
                <></> // rever__code
              ) : d.active ||
                userData?.role?.id === "61134a4473bf6fe15aaef55c" ||
                userData?.id === "62f493ea370a34ced8503138" ? (
                <>
                  <NavLink
                    key={d.id + index + ""}
                    to={`/home/application/${d.code}/${d.diagram_id}?discluded_type=${d.code}`}
                    className="nav-element"
                    onClick={d.id === "66548753f2b6aa1e3e1bc49a" ? handleDropdownToggle : handleDropdownToggleFalse}
                  >
                    <div className="label">
                      <div className="flex flex-start">
                        {t(d?.name)}
                        {d.id === "66548753f2b6aa1e3e1bc49a" &&
                          (dropdownOpen ? (
                            <span>
                              <ExpandLess />
                            </span>
                          ) : (
                            <span>
                              <ExpandMore />
                            </span>
                          ))}
                      </div>
                    </div>
                  </NavLink>
                  {d.id === "66548753f2b6aa1e3e1bc49a" && (
                    <>
                      {dropdownOpen && (
                        <div className="bg-blue-300 rounded-md">
                          <NavLink
                            to={`/home/application/true/${d.code}/${d.diagram_id}/?discluded_type=81`}
                            className="nav-element-drop"
                          >
                            <div className="label text-[#6e8ba1]">Ro'yxatdan o'tkan zahira yer uchastkalari (282)</div>
                          </NavLink>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div key={index}></div>
              )
            )
          : element.children.map((child, i) =>
              child.id !== "the.logic.of.the.discussion.of.applications" ? (
                <NavLink key={child.id + i + "1"} to={child?.path} className="nav-element">
                  <div className="label">{t(child?.title)}</div>
                </NavLink>
              ) : actionLogin ? (
                actionIsLogin ? (
                  <div onClick={() => handleLink(child?.path)} key={child.id + i + "0"} className="nav-element">
                    <div className="label">{t(child?.title)}</div>
                  </div>
                ) : (
                  <div key={child.id} className="p-2">
                    <input
                      className=" border-2 rounded-sm p-1 bg-white"
                      placeholder="Code"
                      onChange={handeChangeNavbar}
                    />
                  </div>
                )
              ) : (
                <div key={i}></div>
              )
            )}
      </div>
    </Collapse>
  )
}

export default React.memo(ChildBlock)
